import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCczx3fbtGLB4ImqQ9NlNU2ipGn7BsUl8o",
  authDomain: "the-dojo-13eaf.firebaseapp.com",
  projectId: "the-dojo-13eaf",
  storageBucket: "the-dojo-13eaf.appspot.com",
  messagingSenderId: "736985457934",
  appId: "1:736985457934:web:ae61df06fdc125a943e35f"
}

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, timestamp, projectStorage, firebase }