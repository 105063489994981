import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { store } from './store.js'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  
  <Provider store={store}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </Provider>
  
  );