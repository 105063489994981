import './Chat.css'

import React, {useState, useRef, useEffect,} from 'react'
import Avatar from './Avatar'
import { useFirestore } from '../hooks/useFirestore'
import { useAuthContext } from '../hooks/useAuthContext'
import {  formatRelative } from 'date-fns'
import { firebase } from '../firebase/config'

export default function Chat({user, messages, closeHandler, loadMoreHandler}) {
    console.log(user)
    
    
    const {addDocument: addMessage, response: responseMessage} = useFirestore('messages')
    const {updateDocument: updateAlert, response: responseAlert} = useFirestore('messageAlerts')
    const { user: currentUser } = useAuthContext()
    const [message,setMessage] =useState('')
    const chatEnd = useRef(null)
    

    //firebase.firestore.FieldValue.arrayUnion(currentUser.uid)

    const handleMessageSubmit = async (e) => {
      e.preventDefault()

      const messageDocument = {
        text: message,
        senderId: currentUser.uid,
        receiverId: user.id,
        messageRead: false,
        participantIds: [user.id, currentUser.uid] 
      }

      
      await addMessage(messageDocument)
      await updateAlert(user.id, {
        fromUserIds: firebase.firestore.FieldValue.arrayUnion(currentUser.uid)
      })
      

      chatEnd.current.scrollIntoView(false)
      setMessage('')
    }

    const formatDate = (date) => {
      return formatRelative(date, new Date())
    }


    useEffect(()=> {
      chatEnd.current.scrollIntoView(false)
      
    },[messages])

  return (
    <div className="chat" >
        <div className="label-top"><Avatar src={user.photoURL} /> <span>{user.displayName}</span> <span onClick={closeHandler}>X</span></div>
        <div className="content" >
          <div className='load-more'>
            <button onClick={loadMoreHandler}>Load more</button>
          </div>
          {messages && messages.map(m=> (
            <div key={m.id} className={`message-container ${currentUser.uid === m.senderId ? 'outgoing' : 'incoming'}`}>
              <div className="message">{m.text}</div>
              <p className="message-time">{formatDate(m.createdAt.toDate())}</p>
            </div>
            ))} 
          <span ref={chatEnd}></span> 
        </div> 
        <div className="label-bottom" >
          <form onSubmit={handleMessageSubmit}>  
            <input value={message} onChange={(e) => setMessage(e.target.value) } required/> 
            <button disabled={responseMessage.isPending || responseAlert.isPending}>Send</button>
            
          </form>
         
        </div>
    </div>
  )
}
