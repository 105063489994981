import { useCollection } from '../hooks/useCollection'
import { useDispatch } from 'react-redux'
import { addUser } from './ChatListSlice'
import { useAuthContext } from '../hooks/useAuthContext'
import {firebase} from '../firebase/config'
import { useFirestore } from '../hooks/useFirestore'

// components
import Avatar from './Avatar'

// styles
import './OnlineUsers.css'
import NewMessage from '../assets/new_message.svg'


export default function OnlineUsers() {
  const { isPending, error, documents } = useCollection('users')
  const { updateDocument: updateAlert } = useFirestore('messageAlerts')
  
  const dispatch = useDispatch()
  const { user: currentUser } = useAuthContext()
  const {  documents: alerts } = useCollection('messageAlerts',[firebase.firestore.FieldPath.documentId(),'==',currentUser.uid])

  console.log('Alerts: ',alerts)

  return (
    <div className="user-list">
      <h2>All Users</h2>
      {isPending && <div>Loading users...</div>}
      {error && <div>{error}</div>}
      {documents && documents.filter(user => user.id!==currentUser.uid).map(user => (
        <div key={user.id} className="user-list-item" onClick={async () => {
            dispatch(addUser(user)) 
            updateAlert(currentUser.uid,{fromUserIds: alerts[0].fromUserIds.filter(a=>a !== user.id)}) 
          }}>
          
          {alerts && alerts[0].fromUserIds.includes(user.id) && (<img src={NewMessage}  className="new-message"/>)}
          {user.online && <span className="online-user" title="user-online"></span>}
          
          <span>{user.displayName}</span>
          <Avatar src={user.photoURL} >
            
          </Avatar>

        </div>
      ))}
    </div>
  )
}
