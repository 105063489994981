// styles
import './Avatar.css'

export default function Avatar({ src, className='',children, ...props }) {
  return (
    <div className={`avatar ${className}`}>
      <img src={src} alt="user avatar" />
      {children}
    </div>
  )
}
