import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  users: [],
  maxMessagesCount: 1000
}

export const chatListSlice = createSlice({
  name: 'chatList',
  initialState,
  reducers: {
    addUser: (state,action) => {
        const user = action.payload 
        //first we have to check if we have this user already
        if(state.users.filter(u => u.id===user.id).length) return
        
        //now we have to check if there are 3 elements in array. If so, we remove first element (max chats = 3)
        if(state.users.length === 3) state.users.shift()
        
        //finnaly we can add element
        state.users.push(user)
    },
    removeUser: (state,action) => {
        const user = action.payload 
        
        state.users = state.users.filter(u => u.id!==user.id)
        
    },
    
    incrementMaxMessages: (state, action) => {
      state.maxMessagesCount = state.maxMessagesCount + action.payload 
    }

  },
})

// Action creators are generated for each case reducer function
export const {  addUser, removeUser, incrementMaxMessages } = chatListSlice.actions

export default chatListSlice.reducer