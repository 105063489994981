import './ChatList.css'

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Chat from './Chat'
import { removeUser, incrementMaxMessages } from './ChatListSlice'
import { useCollection } from '../hooks/useCollection'
import { useAuthContext } from '../hooks/useAuthContext'

export default function ChatList() {



 const chatUsers = useSelector((state) => state.chatList.users)
 const maxMessagesCount = useSelector((state) => state.chatList.maxMessagesCount)
 const dispatch = useDispatch()


 const { user: currentUser } = useAuthContext()
 const { documents: messages } = useCollection('messages',['participantIds','array-contains',currentUser.uid],['createdAt','desc'],maxMessagesCount)
 
 
 console.log(messages)
 

  


  return (
    <div className="chat-list">
        
        {chatUsers.map(user => (
          <Chat 
            user={user} 
            key={user.id} 
            messages={messages?.filter(m => m.receiverId === user.id || m.senderId === user.id ).reverse()} 
            closeHandler={() => dispatch(removeUser(user))}
            loadMoreHandler= {()=> dispatch(incrementMaxMessages(5))}
          /> 
        ))}
    </div>
  )
}
